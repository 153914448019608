<template>
  <div>
    <card-details
      class="mb-5"
      :field="dataParams.field_name"
      :date="dataParams.reservation_date"
      :time="dataParams.time_str"
      :sport="dataParams.sport_name"
      :origin="dataParams.origin"
      :price="dataParams.reservation_price"
      :reservation-type="dataParams.reservation_type"
    />

    <div class="mb-5">
      <label class="font-weight-medium">Add-ons</label>
      <v-card
        color="#c5c5c5"
        class="d-flex align-center mt-2"
        :width="$vuetify.breakpoint.smAndUp ? '320px' : '100%'"
      >
        <v-img
          src="@/assets/images/pages/1.png"
          max-width="100"
          cover
        />
        <v-card-text class="d-flex flex-column py-0">
          <span class="text-body-1">{{ $t('reservations.video_recording') }}</span>
          <span class="text-sm">{{ dataParams.video_price ? formatCurrency(dataParams.video_price) : '$0.00' }}</span>
        </v-card-text>
      </v-card>
    </div>

    <participants
      class="mb-5"
      :is-open="computedIsOpen"
      :edit-mode="true"
      :participants.sync="participants"
      :edit-participants.sync="computedDataParams.players"
      :facility="configFacility"
      :players-qty="computedDataParams.players_qty || 1"
      :per-participant-price="perParticipantPrice"
      :is-validate-form="false"
      :payment-type="computedDataParams.reservation_type"
      :data-params="computedDataParams"
      @selectPlayersQty="val => playersQtySelected = val"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { formatCurrency } from '@core/utils'
import useSelectOptions from '@core/utils/useSelectOptions'

import CardDetails from '@/views/reservations/components/CardDetails.vue'
import Participants from '@/views/reservations/components/Participants.vue'

export default {
  components: {
    CardDetails,
    Participants,
  },
  props: {
    dataParams: {
      type: [Object, null],
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  setup(props) {
    const { configFacility } = useSelectOptions()
    const computedDataParams = computed(() => props.dataParams)
    const computedIsOpen = computed(() => props.isOpen)

    const playersQtySelected = ref(null)
    const perParticipantPrice = ref(0)
    const participants = ref([{
      id: null,
      user_match_id: null,
      contact_id: null,
      user_app_id: null,
      fullname: null,
      phone_number: null,
      email: null,
      final_amount: perParticipantPrice.value || 0,
      paid: 'N',
      payment_medium: null,
      onsite_payment_method_id: null,
      onsite_payment_method_name: null,
      empty: true,
      enabled: true,
      is_organizer: 'Y',
      avatar: null,
      ckecked: false,
    }])

    watch([computedIsOpen], () => {
      participants.value = [{
        id: null,
        user_match_id: null,
        contact_id: null,
        user_app_id: null,
        fullname: null,
        phone_number: null,
        email: null,
        final_amount: perParticipantPrice.value || 0,
        paid: 'N',
        payment_medium: null,
        onsite_payment_method_id: null,
        onsite_payment_method_name: null,
        empty: true,
        enabled: true,
        is_organizer: 'Y',
        avatar: null,
        ckecked: false,
      }]
    })

    return {
      configFacility,
      computedDataParams,
      computedIsOpen,
      participants,
      playersQtySelected,
      perParticipantPrice,

      formatCurrency,
    }
  },
}
</script>

<style>

</style>
