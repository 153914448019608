<template>
  <div>
    <label class="font-weight-medium mb-3">{{ $t('reservations.participants') }}</label>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-btn-toggle
          v-model="qtyToggle"
          color="primary"
          dense
          group
          class="mt-2 btn-qty"
          active-class="btn-qty-active"
          @change="onChangeQty"
        >
          <v-btn
            :value="1"
            class="btn-qty-border text-body-1 pa-0 ma-0 mr-3"
          >
            1
          </v-btn>

          <v-btn
            :value="computedPlayersQtyMinus"
            class="btn-qty-border text-body-1 pa-0 ma-0 mr-3"
          >
            {{ computedPlayersQtyMinus }}
          </v-btn>

          <v-btn
            :value="computedPlayersQty"
            class="btn-qty-border text-body-1 pa-0 ma-0 mr-3"
          >
            {{ computedPlayersQty }}
          </v-btn>

          <v-btn
            :value="computedPlayersQtyPlus"
            class="btn-qty-border text-body-1 pa-0 ma-0 mr-3"
          >
            {{ computedPlayersQtyPlus }}
          </v-btn>

          <v-text-field
            v-model.number="qtyToggleCustom"
            class="text-body-1 d-flex align-center"
            placeholder="#"
            dense
            hide-details
            outlined
            :style="'max-width: 52px !important; min-width: 52px !important;'"
            @keypress="onlyNumbers"
            @change="e => {
              qtyToggle = null
              onChangeQty(e, true)
            }"
            @click.enter="e => {
              qtyToggle = null
              onChangeQty(e, true)
            }"
          ></v-text-field>
        </v-btn-toggle>
      </v-col>

      <v-col
        v-if="removeQtyAlert"
        cols="12"
      >
        <v-alert
          class="mb-0"
          type="error"
        >
          <template #prepend>
            <v-icon class="me-2">
              {{ icons.mdiAlert }}
            </v-icon>
          </template>
          Invalid number of participants
        </v-alert>
      </v-col>

      <v-col
        v-if="organizerAlert && isValidateForm"
        cols="12"
      >
        <v-alert
          class="mb-0"
          type="error"
        >
          <template #prepend>
            <v-icon class="me-2">
              {{ icons.mdiAlert }}
            </v-icon>
          </template>
          Organizer is required
        </v-alert>
      </v-col>
    </v-row>

    <!-- index === 0 && ['F', 'flat'].includes(paymentType) ? $t('reservations.organizer') : participant.empty ? `${$t('reservations.participant')} ${$t('reservations.optional')}` : `${$t('reservations.participant')}`" -->
    <participant-input
      v-for="(participant, index) in computedParticipants"
      :key="index"
      :is-open="computedIsOpen"
      :reservation-id="dataParams.reservation_id"
      :place-index="index"
      :facility="facility"
      :data-params="participant"
      :label="resolveParticipantLabel(participant, index)"
      :selected-participant="selectedParticipant"
      :onsite-payment-methods-options="onsitePaymentMethodsOptions"
      :disabled-price="['P', 'per_participant'].includes(paymentType)"
      :edit-mode="editMode"
      :reservation-data-params="dataParams"
      :per-participant-price="perParticipantPrice"
      @updateNextEnabled="onUpdateNextEnabled"
      @recalculatePayment="$emit('recalculatePayment')"
      @markAsPaid="onMarkOneAsPaid"
    />

    <div class="d-flex my-5">
      <v-btn
        outlined
        class="mr-2"
        :small="$vuetify.breakpoint.smAndDown"
        @click="onAddParticipant"
      >
        <v-icon>{{ icons.mdiPlus }}</v-icon>
        Add participant
      </v-btn>
      <v-btn
        v-if="editMode"
        outlined
        class="mr-2"
        :small="$vuetify.breakpoint.smAndDown"
      >
        Select all
      </v-btn>
    </div>

    <v-row v-if="['flat', 'F'].includes(dataParams.reservation_type) && editMode">
      <v-col cols="12">
        <label class="font-weight-medium mb-2 pb-2">Balance</label>
      </v-col>
      <v-col
        v-if="dataParams.balance_status === 'charged'"
        cols="12"
        md="5"
      >
        <v-text-field
          v-model="computedOrganizer.fullname"
          class="text-body-1"
          label="Organizer"
          dense
          hide-details
          outlined
          @keypress="onlyNumbers"
        ></v-text-field>
      </v-col>

      <v-col
        cols="12"
        :md="dataParams.balance_status === 'charged' ? '7' : '12'"
        class="d-flex align-center"
      >
        <v-text-field
          v-model="dataParams.balance"
          class="text-body-1 mr-2"
          :class="{
            'success--text': dataParams.balance_status === 'charged',
            'error--text': ['pending', 'pass'].includes(dataParams.balance_status),
          }"
          label="Payment"
          dense
          hide-details
          outlined
          @keypress="onlyNumbers"
        ></v-text-field>

        <v-menu
          top
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="btn-cancel btn-dots"
              outlined
              :small="$vuetify.breakpoint.smAndDown"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="22">
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-title>
                action 1
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                action 2
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>

      <v-col
        cols="12"
        class="d-flex align-center"
      >
        <v-icon
          color="info"
          class="mr-1"
        >
          {{ icons.mdiInformationOutline }}
        </v-icon>
        <label>
          <span v-if="dataParams.balance_status === 'charged'">
            <strong>{{ formatCurrency(dataParams.balance || 0) }}</strong> was charged to the organizer's credit card ending in 8764(TO DO) because it was unpaid after {{ formatDate(dataParams.payment_deadline) }}.
          </span>
          <span v-if="dataParams.balance_status === 'pending'">
            <strong>{{ formatCurrency(dataParams.balance || 0) }}</strong> will be charged to the organizer's credit card ending in 8764(TO DO) if unpaid before {{ formatDate(dataParams.payment_deadline) }}.
          </span>
          <span v-if="dataParams.balance_status === 'pass'">
            Balance will not be charged to the organizer because payment was not made through the app.
          </span>
        </label>
      </v-col>
    </v-row>

    <v-row v-if="editMode">
      <v-col
        cols="12"
        class="d-flex align-center"
      >
        <v-text-field
          v-model="checkedParticipantTotal"
          class="text-body-1 mr-2"
          :label="$t('videos.payment')"
          dense
          hide-details
          outlined
          readonly
          @keypress="onlyNumbers"
        ></v-text-field>

        <v-btn
          v-if="!showOnsitePayment"
          color="primary"
          :style="$vuetify.breakpoint.smAndDown ? '' : 'max-width: 240px !important; min-width: 240px;'"
          :disabled="checkedParticipant.length === 0"
          @click="showOnsitePayment = true"
        >
          Mark selected as paid
        </v-btn>

        <v-select
          v-else
          v-model="selectedOnsitePayment"
          :label="$t('settings.onSite_payment_method')"
          :items="onsitePaymentMethodsOptions"
          item-text="name"
          item-value="id"
          class="text-body-1"
          :style="$vuetify.breakpoint.smAndDown ? '' : 'max-width: 240px !important; min-width: 240px;'"
          outlined
          dense
          hide-details
          @change="onSelectOnsitePaymentMethod"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  ref, computed, watch, getCurrentInstance,
} from '@vue/composition-api'
import { getContactFacilitySearch, getOnSitePaymentMethodsActiveByFacility, selectedMarkAsPaidReservation } from '@api'
import {
  mdiPlus,
  mdiAlert,
  mdiDotsVertical,
  mdiInformationOutline,
} from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import { formatCurrency } from '@core/utils'
import {
  required,
  integerValidator,
  regexValidator,
  charUnique,
  between,
  emailValidator,
} from '@core/utils/validation'
import moment from 'moment'
import { onlyNumberDot, onlyNumbers } from '@core/utils/functionsHelpers'
import ParticipantInput from './ParticipantInput.vue'

export default {
  components: {
    ParticipantInput,
  },
  model: {
    prop: 'participants',
    event: 'update:participants',
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    participants: {
      type: Array,
      default: () => [{
        id: null,
        user_match_id: null,
        contact_id: null,
        user_app_id: null,
        fullname: null,
        phone_number: null,
        email: null,
        final_amount: 0,
        paid: 'N',
        payment_medium: null,
        onsite_payment_method_id: null,
        onsite_payment_method_name: null,
        empty: true,
        enabled: true,
        is_organizer: 'Y',
        avatar: null,
        ckecked: false,
      }],
      required: true,
    },
    facility: {
      type: Number,
      default: 0,
      required: true,
    },
    playersQty: {
      type: Number,
      default: 1,
      required: true,
    },
    perParticipantPrice: {
      type: Number,
      default: 0,
      required: true,
    },
    isValidateForm: {
      type: Boolean,
      default: false,
      required: true,
    },
    paymentType: {
      type: String,
      default: 'flat',
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
      required: false,
    },
    editParticipants: {
      type: Array,
      default: () => [],
      required: false,
    },
    dataParams: {
      type: Object,
      default: () => ({
        reservation_id: null,
        recurring_id: null,
        match_id: null,
        field_id: null,
        field_name: null,
        sport_name: null,
        reservation_date: null,
        status: null,
        status_str: null,
        payment_status: null,
        payment_status_str: null,
        payment_type: null,
        reservation_type: null,
        reservation_time: null,
        reservation_time_int: null,
        start_time: null,
        end_time: null,
        video_id: null,
        video_thumbnail: null,
        include_video: null,
        time_str: null,
        price_per_player: null,
        reservation_price: null,
        video_price: null,
        others_products_price: null,
        balance: null,
        reservation_share_url: null,
        players: [],
        players_qty: null,
        players_qty_max: null,
        players_qty_min: null,
        cancel_deadline: null,
        payment_deadline: null,
        payment_medium: null,
        origin: null,
      }),
      required: true,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const { t, tInContext } = useUtils()
    const computedFacility = computed(() => props.facility)
    const computedIsOpen = computed(() => props.isOpen)
    const computedPerParticipantPrice = computed(() => props.perParticipantPrice)
    const computedParticipants = computed({
      get: () => props.participants,
      set: val => emit('update:participants', val),
    })
    const computedOrganizer = computed(() => computedParticipants.value.filter(e => e.is_organizer === 'Y'))
    const computedEditParticipants = computed({
      get: () => props.editParticipants,
      set: val => emit('update:editParticipants', val),
    })
    const selectedParticipant = computed(() => computedParticipants.value.filter(e => e.id).map(e => e.id))
    const checkedParticipant = computed(() => computedParticipants.value.filter(e => e.checked))
    const checkedParticipantTotal = computed(() => ((computedParticipants.value.filter(e => e.checked).reduce((a, b) => a + b.final_amount, 0)) * 100) / 100)
    const computedPlayersQty = computed(() => props.playersQty)
    const computedPlayersQtyPlus = computed(() => props.playersQty + 2)
    const computedPlayersQtyMinus = computed(() => props.playersQty - 2)
    const empties = computed(() => computedParticipants.value.filter(e => e.empty).length)
    const showOnsitePayment = ref(false)
    const selectedOnsitePayment = ref(null)
    const participantsSearched = ref([])
    const qtyToggle = ref(props.playersQty)
    const qtyToggleCustom = ref(null)
    const contactSelectedId = ref(null)
    const searchQuery = ref(null)
    const searchLoading = ref(false)
    const timerSearch = ref(null)
    const onsitePaymentMethodsOptions = ref([])
    const removeQtyAlert = ref(false)
    const organizerAlert = computed(() => computedParticipants.value.every(e => e.empty))

    const resolveParticipantLabel = (participant, index) => {
      let textLabel = ''
      if (index === 0 && ['F', 'flat'].includes(props.paymentType)) textLabel = t('reservations.organizer')
      else if (participant.empty) textLabel = `${t('reservations.participant')} (${t('reservations.optional')})`
      else textLabel = `${t('reservations.participant')}`

      return textLabel.trim()
    }

    const fetchContacts = async () => {
      if (searchQuery.value) {
        searchLoading.value = true
        const response = await getContactFacilitySearch(props.facility || 0, searchQuery.value)
        if (response.ok && response.data.length) {
          const newContact = [{
            id: 0,
            first_name: searchQuery.value,
            last_name: searchQuery.value,
            fullname: searchQuery.value,
            phone_number: searchQuery.value,
            email: searchQuery.value,
          }]

          participantsSearched.value = newContact.concat(response.data)
        } else {
          participantsSearched.value = []
        }
        searchLoading.value = false
      }
    }

    const getDataBySearch = async () => {
      clearTimeout(timerSearch.value)
      timerSearch.value = setTimeout(async () => {
        await fetchContacts()
      }, 1000)
    }

    watch([searchQuery], async () => {
      if (contactSelectedId.value) {
        const firSearch = searchQuery.value
        contactSelectedId.value = null
        setTimeout(() => { searchQuery.value = firSearch }, 50)
      }
      await getDataBySearch()
    })

    const addContact = index => {
      emit('addContact', searchQuery.value, index)
    }

    const onAddContact = (item, index) => {
      computedParticipants.value.at(index).id = item.id
      computedParticipants.value.at(index).app_user_id = item.id
      computedParticipants.value.at(index).price = null
      computedParticipants.value.at(index).paid = null
    }

    const onSelectContact = (val, index) => {
      if (val) {
        const itemDelected = participantsSearched.value.find(e => e.id === val)
        onAddContact(itemDelected, index)
      } else if (val === 0) {
        contactSelectedId.value = null

        participantsSearched.value = []

        // autoContact.value.reset()
        document.getElementById(`autoContact-${val}`).reset()
        addContact()
      } else {
        contactSelectedId.value = null
        participantsSearched.value = []
      }
    }

    const onMarkOneAsPaid = async participant => {
      const usersMatch = [{
        user_match_id: participant.user_match_id,
        final_amount: participant.final_amount,
        onsite_payment_method_id: participant.onsite_payment_method_id,
      }]
      await selectedMarkAsPaidReservation(props.dataParams.reservation_id, { users_match: usersMatch })
    }

    const onMarkSelectedAsPaid = async () => {
      const usersMatch = checkedParticipant.value.map(e => ({
        user_match_id: e.user_match_id,
        final_amount: e.final_amount,
        onsite_payment_method_id: selectedOnsitePayment.value,
      }))
      const resp = await selectedMarkAsPaidReservation(props.dataParams.reservation_id, { users_match: usersMatch })
      if (resp.ok) {
        computedParticipants.value.forEach(e => {
          if (usersMatch.findIndex(i => i.user_match_id === e.user_match_id) > -1) e.paid = 'Y'
        })
      }
      showOnsitePayment.value = false
    }

    const onSelectOnsitePaymentMethod = async onsitePaymentMethodId => {
      if (onsitePaymentMethodId === null || onsitePaymentMethodId === '*') {
        showOnsitePayment.value = false
      } else {
        selectedOnsitePayment.value = onsitePaymentMethodId
        onMarkSelectedAsPaid()
      }
    }

    const onChangeQty = (val, custom = false) => {
      if (!custom) qtyToggleCustom.value = null
      if (val && !Number.isNaN(val) && val > 0) {
        const qty = parseInt(val, 10)

        // emit('selectPlayersQty', qty)
        const validRange = (between(qty, 1, 100))() === true
        removeQtyAlert.value = false
        if (qty > computedParticipants.value.length) {
          if ((props.editMode && qty > props.dataParams.players_qty_max) || (!props.editMode && !validRange)) {
            removeQtyAlert.value = true
          } else {
            emit('selectPlayersQty', qty)
            const newElements = qty - computedParticipants.value.length
            for (let i = 0; i < newElements; i += 1) {
              computedParticipants.value.push({
                id: null,
                user_match_id: null,
                contact_id: null,
                user_app_id: null,
                fullname: null,
                phone_number: null,
                email: null,
                final_amount: props.perParticipantPrice || 0,
                paid: 'N',
                payment_medium: null,
                onsite_payment_method_id: null,
                onsite_payment_method_name: null,
                empty: true,
                enabled: empties.value === 0,
                is_organizer: 'N',
                avatar: null,
                ckecked: false,
                edited: false,
              })
            }
            removeQtyAlert.value = false
          }
        }

        if (qty < computedParticipants.value.length) {
          const remove = computedParticipants.value.length - qty
          if (remove > empties.value || (props.editMode && qty < props.dataParams.players_qty_min) || (!props.editMode && !validRange)) {
            removeQtyAlert.value = true
          } else {
            emit('selectPlayersQty', qty)
            for (let i = 0; i < remove; i += 1) {
              computedParticipants.value.pop()
            }
            removeQtyAlert.value = false
          }
        }
      }
    }

    const onAddParticipant = () => {
      qtyToggleCustom.value = computedParticipants.value.length + 1
      onChangeQty(qtyToggleCustom.value, true)

      // computedParticipants.value.push({
      //   id: null,
      //   user_match_id: null,
      //   contact_id: null,
      //   user_app_id: null,
      //   fullname: null,
      //   phone_number: null,
      //   email: null,
      //   final_amount: props.perParticipantPrice || 0,
      //   paid: 'N',
      //   payment_medium: null,
      //   onsite_payment_method_id: null,
      //   onsite_payment_method_name: null,
      //   empty: true,
      //   enabled: empties.value === 0,
      //   is_organizer: 'N',
      //   avatar: null,
      //   ckecked: false,
      // })
      // qtyToggleCustom.value = null
    }

    const onUpdateNextEnabled = inx => {
      if (inx <= computedParticipants.value.length - 1) computedParticipants.value.at(inx).enabled = true
    }

    const formatDate = date => {
      const momentDate = moment(date, 'YYYY-MM-DD HH:mm')

      return momentDate.format('MMMM DD, YYYY, h:mma')
    }

    watch([computedPerParticipantPrice], () => {
      computedParticipants.value.forEach(e => {
        e.final_amount = e.paid === 'N' && !e.edited ? computedPerParticipantPrice.value : e.final_amount
      })
    })

    watch([computedPlayersQty, computedIsOpen], () => {
      qtyToggleCustom.value = null
      onChangeQty(computedPlayersQty.value)

      // for (let i = 0; i < computedPlayersQty.value - 1; i += 1) {
      //   computedParticipants.value.push({
      //     id: null,
      //     user_match_id: null,
      //     contact_id: null,
      //     user_app_id: null,
      //     fullname: null,
      //     phone_number: null,
      //     email: null,
      //     final_amount: props.perParticipantPrice || 0,
      //     paid: 'N',
      //     payment_medium: null,
      //     onsite_payment_method_id: null,
      //     onsite_payment_method_name: null,
      //     empty: true,
      //     enabled: empties.value === 0,
      //     is_organizer: i === 0 ? 'Y' : 'N',
      //     avatar: null,
      //     ckecked: false,
      //   })
      // }

      if (computedEditParticipants.value.length) {
        computedEditParticipants.value.forEach((e, i) => {
          computedParticipants.value.at(i).id = e.id
          computedParticipants.value.at(i).user_match_id = e.user_match_id
          computedParticipants.value.at(i).contact_id = e.contact_id
          computedParticipants.value.at(i).user_app_id = e.user_app_id
          computedParticipants.value.at(i).fullname = e.fullname
          computedParticipants.value.at(i).phone_number = e.phone_number
          computedParticipants.value.at(i).email = e.email
          computedParticipants.value.at(i).final_amount = e.final_amount
          computedParticipants.value.at(i).paid = e.paid
          computedParticipants.value.at(i).onsite_payment_method_id = e.onsite_payment_method_id
          computedParticipants.value.at(i).onsite_payment_method_name = e.onsite_payment_method_name
          computedParticipants.value.at(i).empty = e.empty
          computedParticipants.value.at(i).enabled = e.enabled
          computedParticipants.value.at(i).is_organizer = e.is_organizer
          computedParticipants.value.at(i).payment_medium = e.payment_medium
          computedParticipants.value.at(i).avatar = e.avatar
          computedParticipants.value.at(i).ckecked = e.ckecked
        })

        if (computedParticipants.value.length > computedEditParticipants.value.length) {
          computedParticipants.value.at(computedEditParticipants.value.length).enabled = true
        }
      }

      // }, 100)
    })

    watch([computedFacility], async () => {
      if (computedFacility.value) {
        onsitePaymentMethodsOptions.value = []
        const onsitePaymentMethods = await getOnSitePaymentMethodsActiveByFacility(props.facility)
        if (onsitePaymentMethods.ok) {
          onsitePaymentMethodsOptions.value = onsitePaymentMethods.data
          onsitePaymentMethodsOptions.value.unshift({
            id: '*',
            name: tInContext('tooltip.unpaid', vm),
          })
        }
      }
    }, { immediate: true })

    return {
      computedPerParticipantPrice,
      computedParticipants,
      computedOrganizer,
      computedIsOpen,
      selectedParticipant,
      checkedParticipant,
      checkedParticipantTotal,
      participantsSearched,
      computedPlayersQty,
      computedPlayersQtyPlus,
      computedPlayersQtyMinus,
      qtyToggle,
      qtyToggleCustom,
      contactSelectedId,
      searchQuery,
      searchLoading,
      onsitePaymentMethodsOptions,
      showOnsitePayment,
      selectedOnsitePayment,

      removeQtyAlert,
      organizerAlert,

      resolveParticipantLabel,
      onSelectOnsitePaymentMethod,
      onMarkOneAsPaid,
      onMarkSelectedAsPaid,
      onAddParticipant,
      onSelectContact,
      onAddContact,
      addContact,
      avatarText,
      onChangeQty,
      onUpdateNextEnabled,
      formatCurrency,
      formatDate,

      required,
      integerValidator,
      regexValidator,
      charUnique,
      between,
      emailValidator,
      onlyNumberDot,
      onlyNumbers,

      icons: {
        mdiPlus,
        mdiAlert,
        mdiDotsVertical,
        mdiInformationOutline,
      },
    }
  },
}
</script>
<style>
.v-list-item {
  height: 100% !important;
}

.btn-qty-border {
  border: 1px solid #00000024 !important;
  border-radius: 5px !important;
  min-width: 52px !important;
  max-width: 52px !important;
  max-height: 40px !important;
  min-height: 40px !important;
}

.btn-qty > .v-btn-toggle--group > .v-btn.v-btn,
.btn-qty .v-btn-toggle--group > .v-btn.v-btn {
  background-color: #b1b1b1 !important;
}

.btn-qty .btn-qty-active button {
  background-color: #b1b1b1 !important;
}

.btn-dots {
  min-width: 38px !important;
  padding: 0 !important;
}

@media (max-width: 599px) {
  .btn-dots {
    max-width: 38px !important;
    min-height: 38px !important;
    max-height: 38px !important;
  }
}
</style>
