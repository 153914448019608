/* eslint-disable arrow-body-style */
/* eslint-disable object-curly-newline */
import { ref, computed, watch, nextTick } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { getRGBColor, getVuetify, isDateToday } from '@core/utils'
import useCryptoJs from '@core/utils/useCryptoJs'
import {
  getReservationFacilityWeekRange,
  getReservationByFacilityDate,
  getFieldsByFacility,
  getPlayersGroupsByFacility,
  getOnSitePaymentMethodsActiveByFacility,
  getReservationDetail,
  getReservationNoReasonByFacility,
  getCancellationReasonsActiveByGroup,
} from '@api'
import useSelectOptions from '@/@core/utils/useSelectOptions'

export default function useReservations() {
  const { t } = useUtils()
  const { configOrganization, configFacility, organizationsOptions, facilitiesOptions } = useSelectOptions(true)
  const { userData } = useCryptoJs()
  const $vuetify = getVuetify()

  const activeCalendarView = ref('category')
  const calendarViewOptions = ['month', 'week', 'day', 'category']
  const categories = ref([])
  const intervalCount = ref(96)
  const firstTime = ref('00:00')
  const offsetHours = ref(0)
  const scrollTime = ref(0)
  const facilityOpenTime = ref('')
  const facilityCloseTime = ref('')
  const facilityOpen = ref(true)

  const calendarValue = ref()
  const calendarEvents = ref([])
  const calendarEventsAll = ref([])
  const calendarEventsNoReason = ref([])
  const calendarOptions = ref([
    {
      color: 'recurring',
      label: 'recurring',
    },
    {
      color: 'regular',
      label: 'regular',
    },
    {
      color: 'group',
      label: 'group',
    },
    {
      color: 'other',
      label: 'other',
    },
    {
      color: 'pickUp',
      label: 'pick_up',
    },
    {
      color: 'academy',
      label: 'academy',
    },
    {
      color: 'league',
      label: 'league',
    },
    {
      color: 'match',
      label: 'match',
    },
    {
      color: 'birthday',
      label: 'birthday',
    },
    {
      color: 'tournament',
      label: 'tournament',
    },
    {
      color: 'blocked',
      label: 'blocked',
    },
    {
      color: 'per_participant',
      label: 'per_participant',
    },
    {
      color: 'flat',
      label: 'flat',
    },
  ])
  const calendarEventDetail = ref({})

  const cancellationReasons = ref([])

  const tabs = ref([])
  const tabDay = ref(1)
  const dateStr = ref('')
  const dateRangeStr = ref('')
  const reservationRange = ref(0)

  const reservationCustomDate = ref('')

  const fieldsOptions = ref([])
  const playersGroupsOptions = ref([])
  const onSitePaymentMethods = ref([])

  const statusOptions = computed(() => [
    { text: t('status.confirmed'), value: 'C' },
    { text: t('status.pending'), value: 'N' },
  ])

  const typesOptions = computed(() => [
    { text: t('reservations.group_match'), value: 'group', color: 'group' },
    { text: t('reservations.pick_up_match'), value: 'pick_up', color: 'pickUp' },
    { text: t('reservations.regular_match'), value: 'regular', color: 'pickUp' },
    { text: t('reservations.academy'), value: 'academy', color: 'academy' },
    { text: t('reservations.birthday'), value: 'birthday', color: 'birthday' },
    { text: t('reservations.tournament'), value: 'tournament', color: 'tournament' },
    { text: t('reservations.other'), value: 'other', color: 'other' },
  ])

  const selectedCalendars = computed(() => [])

  const fieldFilter = ref(0)
  const durationFilter = ref(0)
  const typesFilter = ref([])
  const statusFilter = ref([])
  const dateRangeStart = ref('')
  const dateRangeEnd = ref('')

  const dateFilter = ref(
    new Date().toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  const now = ref(new Date())
  const nowDate = ref(
    new Date(now.value).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  const min = ref(+new Date() - 12096e5)
  const minDate = ref(
    new Date(min.value).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  const max = ref(+new Date() + 12096e5)
  const maxDate = ref(
    new Date(max.value).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  const checkAll = computed({
    get: () => selectedCalendars.value.length === calendarOptions.value.length,
    set: val => {
      if (val) {
        selectedCalendars.value = calendarOptions.value.map(i => i.label)
      } else if (selectedCalendars.value.length === calendarOptions.value.length) {
        selectedCalendars.value = []
      }
    },
  })

  // ————————————————————————————————————
  //* ——— Scroll To
  // ————————————————————————————————————
  const refCalendar = ref(null)
  const cal = computed(() => refCalendar.value)

  const getCurrentTime = isToday => {
    if (cal.value) {
      let scrollValue = 0
      if (isToday) scrollValue = cal.value.times.now.hour * 60 + cal.value.times.now.minute
      else {
        const [hh, mm] = scrollTime.value.split(':')
        scrollValue = parseInt(hh, 10) * 60 + parseInt(mm, 10)
      }

      return scrollValue
    }

    return 0
  }

  const scrollToTime = (time, isToday = false) => {
    if (cal.value.type !== 'category') nextTick(() => cal.value.scrollToTime(getCurrentTime(isToday)))

    // const y = isToday ? cal.value.timeToY(time) : cal.value.timeToY(540)
    const y = cal.value.timeToY(time)

    if (y === false || !cal.value.$el) {
      return false
    }

    // TODO bug scroll to when end time is less 00:00
    cal.value.$el.scrollTop = y

    return !!cal.value.$el.scrollTop
  }

  // ————————————————————————————————————
  //* ——— colors
  // ————————————————————————————————————

  const resolvePaymentStatusColor = status => {
    if (status === 'N') return 'revervation'
    if (status === 'P') return 'paid'
    if (status === 'H') return 'info'
    if (status === 'D') return 'warning'

    return 'revervation'
  }

  // ————————————————————————————————————
  //* ——— fetchs
  // ————————————————————————————————————

  // Events
  const filterEvents = async () => {
    if (calendarEventsAll.value) {
      let events = calendarEventsAll.value

      if (typesFilter.value.length) {
        events = events.filter(fetchedEvent => typesFilter.value.includes(fetchedEvent.extended_props.type))
      }

      if (statusFilter.value.length) {
        events = events.filter(fetchedEvent => statusFilter.value.includes(fetchedEvent.extended_props.payment_status))
      }

      calendarEvents.value = events
    }
  }

  const fetchEvents = async (facilityId = 0, day = null) => {
    if (day) dateStr.value = day
    calendarValue.value = dateStr.value
    const response = await getReservationByFacilityDate(facilityId, dateStr.value, false)

    if (response.ok) {
      facilityOpen.value = response.data.facility_open
      facilityOpenTime.value = response.data.facility_open_time
      facilityCloseTime.value = response.data.facility_close_time
      offsetHours.value = response.data.offset_hours
      scrollTime.value = response.data.scroll_to_time
      intervalCount.value = response.data.interval_count
      firstTime.value = response.data.facility_open_time
      categories.value = response.data.fields
      calendarEventsAll.value = response.data.reservations
      let events = []
      if (response.data && response.data.reservations) {
        events = response.data.reservations
        events.forEach(fetchedEvent => {
          /* eslint-disable no-param-reassign */
          fetchedEvent.start = new Date(fetchedEvent.start)
          fetchedEvent.end = new Date(fetchedEvent.end)

          fetchedEvent.name = fetchedEvent.group_name
          fetchedEvent.extended_props.date_str = response.data.date_str

          // fetchedEvent.extendedProps.calendar,
          const eventColor = calendarOptions.value.find(calendar => {
            if (fetchedEvent.extended_props.card_type && fetchedEvent.extended_props.card_type.includes('blocked')) {
              return calendar.label === 'blocked'
            }

            return calendar.label === fetchedEvent.extended_props.type
          })
          const rgbColor = getRGBColor(eventColor.color, $vuetify.theme)

          fetchedEvent.border = rgbColor
          fetchedEvent.color = resolvePaymentStatusColor(fetchedEvent.payment_status)
          fetchedEvent.eventTextColor = 'white'
          /* eslint-enable */
        })
      }

      // typesFilter.value = []
      // statusFilter.value = []

      calendarEvents.value = events

      if (typesFilter.value.length || statusFilter.value.length) filterEvents()

      const isToday = isDateToday(nowDate.value, calendarValue.value)
      if (cal.value && response.data.facility_open) scrollToTime(getCurrentTime(isToday), isToday)
    } else {
      categories.value = []
      calendarEvents.value = []
    }
  }

  const hasEventByHour = (hour, minute) => {
    calendarEventsAll.value.some(e => {
      const isHour = hour >= e.start.getHours() && hour < e.end.getHours()
      const isMin = minute >= e.start.getMinutes() && minute < e.end.getMinutes()

      const isTime = isHour && isMin

      return isTime
    })
  }

  // Week Range
  const fetchWeekRange = async (range = 0, dateReservation = null) => {
    let dateRange = nowDate.value
    if (dateReservation) dateRange = dateReservation
    else {
      if (range === 1) dateRange = tabs.value[0].date_str
      if (range === 2) dateRange = tabs.value[6].date_str
      dateRangeStr.value = dateRange
    }
    reservationRange.value = range
    const resp = await getReservationFacilityWeekRange(configFacility.value || 0, dateRange, range, false)
    if (resp.ok) {
      if (!dateReservation) dateStr.value = resp.data[range === 0 ? 1 : 0].date_str
      tabs.value = resp.data.map(e => {
        const obj = {
          ...e,
          title: e.date_name,
          disabled: false,
          hide: false,
        }

        return obj
      })
      dateRangeStart.value = tabs.value[0].date_str
      dateRangeEnd.value = tabs.value[6].date_str
    } else {
      dateStr.value = nowDate.value
    }

    await fetchEvents(configFacility.value || 0)
  }

  // Event Detail
  const fetchEventDetail = async id => {
    const resp = await getReservationDetail(id)
    calendarEventDetail.value = resp.data
    fieldFilter.value = calendarEventDetail.value.field_id
    durationFilter.value = calendarEventDetail.value.reservation_time_int
  }

  // Event No Reason
  const fetchEventNoReason = async facilityId => {
    const resp = await getReservationNoReasonByFacility(facilityId || 0)
    calendarEventsNoReason.value = resp.data
  }

  const fetchFields = async facilityId => {
    const fields = await getFieldsByFacility(facilityId)
    fieldsOptions.value = fields.data
  }

  const fetchPlayersGroups = async facilityId => {
    const resp = await getPlayersGroupsByFacility(facilityId)
    playersGroupsOptions.value = resp.data
  }

  const fetchOnSitePaymentMethods = async facilityId => {
    const resp = await getOnSitePaymentMethodsActiveByFacility(facilityId)
    onSitePaymentMethods.value = resp.data
  }

  const fetchCancellationReasons = async groupId => {
    const resp = await getCancellationReasonsActiveByGroup(groupId || 0)
    if (resp.ok) {
      cancellationReasons.value = resp.data.map(e => ({
        ...e,
        desc: 0,
        title: e.name,
        subtitle: null,
        value: e.id,
      }))
    } else {
      cancellationReasons.value = []
    }
  }

  watch([typesFilter, statusFilter], async () => {
    await filterEvents()
  })

  watch([configFacility], async () => {
    typesFilter.value = []
    if (configFacility.value) {
      // await fetchEventNoReason(configFacility.value)
      await fetchFields(configFacility.value)
      await fetchPlayersGroups(configFacility.value)
      await fetchOnSitePaymentMethods(configFacility.value)
    } else {
      fieldsOptions.value = []
      playersGroupsOptions.value = []
    }
    await fetchEvents(configFacility.value || 0)
  })

  return {
    userData,

    activeCalendarView,
    calendarViewOptions,
    categories,
    tabs,
    tabDay,
    dateStr,
    dateRangeStr,
    reservationRange,
    reservationCustomDate,
    intervalCount,
    firstTime,
    offsetHours,
    scrollTime,
    facilityOpenTime,
    facilityCloseTime,
    facilityOpen,
    cancellationReasons,

    refCalendar,
    cal,
    calendarValue,
    calendarEvents,
    calendarEventDetail,
    calendarOptions,
    selectedCalendars,
    calendarEventsNoReason,

    fieldsOptions,
    playersGroupsOptions,
    onSitePaymentMethods,
    statusOptions,
    typesOptions,
    fieldFilter,
    durationFilter,
    typesFilter,
    statusFilter,
    dateRangeStart,
    dateRangeEnd,

    configOrganization,
    configFacility,
    organizationsOptions,
    facilitiesOptions,

    dateFilter,
    now,
    min,
    max,
    nowDate,
    minDate,
    maxDate,

    t,
    fetchEvents,
    fetchEventDetail,
    fetchWeekRange,
    fetchEventNoReason,
    fetchFields,
    fetchPlayersGroups,
    fetchOnSitePaymentMethods,
    fetchCancellationReasons,
    checkAll,
    getCurrentTime,
    scrollToTime,
    getRGBColor,
    resolvePaymentStatusColor,
    hasEventByHour,
  }
}
